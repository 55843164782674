import { EnvironmentNames } from 'src/app/_core/contants/environments';
import { EFT_INSTRUCTIONS_CNCF, WIRING_INSTRUCTIONS_SWISS } from 'src/app/_core/contants/wiring-instructions';
import { environment as swissEnv } from 'src/environments/swiss/environment.swiss-dev';

export const environment = {
  // Assets
  primaryColor: 'CONSTANT_PRIMARYCOLOR',
  secondaryColor: 'CONSTANT_SECONDARYCOLOR',
  ternaryColor: 'CONSTANT_TERNARYCOLOR',
  logo: 'CONSTANT_LOGO',
  publicLogo: 'CONSTANT_PUBLICLOGO',
  lightDonateNowButtonLink: 'CONSTANT_LIGHTDONATENOWBUTTONLINK',
  darkDonateNowButtonLink: 'CONSTANT_DARKDONATENOWBUTTONLINK',
  lightDonateNowButtonSrc: 'CONSTANT_LIGHTDONATENOWBUTTONSRC',
  darkDonateNowButtonSrc: 'CONSTANT_DARKDONATENOWBUTTONSRC',
  favicon: 'CONSTANT_FAVICON',
  wiringInstructions: 'CONSTANT_WIRINGINSTRUCTIONS',
  eftInstructions: 'CONSTANT_EFTINSTRUCTIONS',
  stripeKey: 'CONSTANT_STRIPEKEY',
  homeLink: 'CONSTANT_HOMELINK',
  contact: 'CONSTANT_CONTACT',
  blog: 'CONSTANT_BLOG',
  privacyPolicy: 'CONSTANT_PRIVACYPOLICY',
  terms: 'CONSTANT_TERMS',
  mailTo: 'CONSTANT_MAILTO',
  name: 'CONSTANT_NAME',
  // Server
  tenantId: 'CONSTANT_TENANTID',
  server: 'CONSTANT_SERVER',
  url: 'CONSTANT_URL',
  // Frontend Logic
  environmentName: EnvironmentNames.ENVIRONMENT_NAME,
  languages: ['CONSTANT_LANGUAGE'],
  production: false,
  multiCurrency: true,
  redirectSourceUrl: 'CONSTANT_REDIRECTSOURCEURL',
  backendClientId: 'CONSTANT_BACKENDCLIENTID',
  authConfig: {
    issuer: 'CONSTANT_ISSUER',
    redirectUri: window.location.origin,
    clientId: 'CONSTANT_CLIENTID',
    responseType: 'CONSTANT_RESPONSETYPE',
    scope: 'CONSTANT_SCOPE',
    showDebugInformation: true,
    sessionChecksEnabled: true,
  },
  title: 'Financial Management System',
};
